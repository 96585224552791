import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  // Create Pickup Form
  { name: 'getWarehousesApiPlatform', url: `${environment.apiPlatform}/v1/pucon/companies/{shipper_id}/warehouses`, method: 'get' },
  // GET Pickups
  { name: 'getPickups', url: `${environment.puconApiUrl}/pickups`, method: 'get' },

  // GET summaries
  { name: 'getSummaries', url: `${environment.estambulApiUrl}/summaries`, method: 'get'},
  // POST Download pickups
  { name: 'downloadPickups', url: `${environment.puconApiUrl}/pickups-download`, method: 'post'},
  // GET Carriers distribution
  { name: 'getCarriersDistribution', url: `${environment.puconApiUrl}/shippers/{shipper_id}/carriers`, method: 'get' },
  // GET Carriers
  { name: 'getCarriers', url: `${environment.puconApiUrl}/carriers`, method: 'get' },
  // GET NonWorkingDays
  { name: 'getNonWorkingDays', url: `${environment.puconApiUrl}/carriers/{carrier_code}/non-working-days`, method: 'get' },
  // GET'S Companies (Shippers / Organizations)
  // { name: 'getShippers', url: `${environment.dubaiApiUrl}/shippers?paginate_by=40`, method: 'get' },
  { name: 'getShippers', url: `${environment.puconApiUrl}/shippers`, method: 'get' },
  { name: 'getShipper', url: `${environment.dubaiApiUrl}/shippers/{shipper_id}`, method: 'get' },
  { name: 'getShippersByOrganizationSimplified', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers?simplified=true`, method: 'get' },
  { name: 'getShippersByOrganizationPucon', url: `${environment.puconApiUrl}/organizations/{organization_id}/shippers`, method: 'get' },
  { name: 'getOrganizations', url: `${environment.dubaiApiUrl}/organizations?organization_type=1`, method: 'get' },
  // GET Countries
  { name: 'getCountries', url: `${environment.dubaiApiUrl}/countries`, method: 'get', notCancel: true},
  // POST Create normal pickup
  { name: 'createNormalPickup', url: `${environment.puconApiUrl}/shippers/{shipper_id}/pickups`, method: 'post' },
  // Cancel pickup
  { name: 'cancelPickup', url: `${environment.puconApiUrl}/shippers/{shipper_id}/pickups/{identifier}/cancel`, method: 'put'},
  { name: 'getCarrierConfig', url: `${environment.puconApiUrl}/carriers/{carrier_code}/configuration/{type}`, method: 'get'},
  // Evaluate pickup
  { name: 'postEvaluatePickup', url: `${environment.puconApiUrl}/shippers/{shipper_id}/pickup/{identifier}/evaluations`, method: 'post'},
  
  // Frequent pickups
  // GET frequent pickups
  { name: 'getFrequentPickups', url: `${environment.puconApiUrl}/frequent-pickups`, method: 'get' },
  // GET frequent pending request
  { name: 'getPendingRequests', url: `${environment.puconApiUrl}/pending-requests`, method: 'get' },
  // Disable Frequent GET
  { name: 'getDisableFrequentReasons', url: `${environment.puconApiUrl}/frequent-deactivation-reasons`, method: 'get'},
  // Disable Frequent PUT
  { name: 'disableFrequent', url: `${environment.puconApiUrl}/shippers/{shipper_id}/frequent-pickups/{identifier}/deactivate`, method: 'put'},
  // POST Create frequent pickup
  { name: 'createFrequentPickup', url: `${environment.puconApiUrl}/shippers/{shipper_id}/frequent-pickups`, method: 'post' },
  // PUT Approve or reject frequent pickup
  { name: 'approveOrRejectFrequentPickup', url: `${environment.puconApiUrl}/shippers/{shipper_id}/frequent-pickups/{identifier}/requests/{requests_id}`, method: 'put' },
  // POST Edit pickup (create request)
  { name: 'createEditRequest', url: `${environment.puconApiUrl}/shippers/{shipper_id}/frequent-pickups/{identifier}/requests`, method: 'post' },
  // PUT Edit pickup (by team-pickup)
  { name: 'directUpdatePickup', url: `${environment.puconApiUrl}/shippers/{shipper_id}/frequent-pickups/{identifier}`, method: 'put' }

]

export default class tabPickupsService {
  constructor(context) {
    this.context = context
  }
  async callService (name, queryParams = {}, params = {}, config = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params, config)
  }
  
  async callMultipleServices (callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }

  /**
   * Funcion que setea permisos a la vista
   * @param {String} role Role del usuario
   * @returns Objeto con los permisos del usuario en base a su role
   */
  conditionsByRole (role) {
    const obj = {
      show_download_pickups: ['admin', 'superadmin'].includes(role),
      show_pending_request: ['admin', 'superadmin'].includes(role),
      show_shipper_field: ['marketplace', 'admin', 'superadmin'].includes(role)
    }
    return obj
  }
  /**
   * Funcion que setea permisos de usuarios equipo de retiros
   * @param {String} role Datos del usuario
   * @returns Objeto con los permisos del equipo de retiros
   */
  conditionsByPermissions (user) {
    // Arreglo de permisos del usuario
    const permissions = user.special_permissions      
    const obj = {
      is_team_pickup: permissions.includes('is-team-pickup'),
      is_admin_team_pickup: permissions.includes('is-admin-team-pickup'),
      frequent_marketplace_supervisor: permissions.includes('frequent-marketplace-supervisor')
    }
    return obj
  }

  /**
   * Función que saca columnas de la tabla 
   * @param {Array} columnsToExclude Arreglo de columnas a excluir de la tabla original
   */
  excludeColumnsInTable (columnsToExclude, schema) {
    return schema.filter(column => !columnsToExclude.includes(column.key))
  }
}